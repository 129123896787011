var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-center px-2 py-3 bg-white rounded-t-lg"},[(_vm.isMobile)?_c('div',{staticClass:"ml-5 mr-10"},[_c('button',{on:{"click":function($event){$event.preventDefault();_vm.$emit('update:showProviderReceivers', true);
                    _vm.$emit('update:showChatRooms', true);
                    _vm.$emit('update:showChats', false);
                    _vm.setCurrentBreadcrumbByReceiver(null);}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-left']}})],1)]):_vm._e(),_c('div',{on:{"click":function($event){return _vm.goProfile(
                    _vm.receiverInfo.userData.role,
                    _vm.receiverInfo.userData.id
                )}}},[_c('Avatar',{attrs:{"backgroundImg":_vm.receiverInfo.userData.avatar,"size":['w-10', 'h-10', 'flex-none']}})],1),_c('span',{staticClass:"flex-1 ml-2 text-blue-500"},[_vm._v(" "+_vm._s(_vm.receiverInfo.userData.name)+" ")]),(_vm.receiverInfo.isBot && _vm.$route.name === _vm.checkRouteName)?_c('button',{directives:[{name:"permission",rawName:"v-permission",value:(['update']),expression:"['update']"}],staticClass:"border border-orange-600 text-sm text-orange-600 px-2 py-0.5 rounded duration-500 mr-3",on:{"click":function($event){return _vm.setServiceChatToReal()}}},[_vm._v(" 切換為真人客服 ")]):_vm._e(),(!_vm.receiverInfo.isBot && _vm.$route.name === _vm.checkRouteName)?_c('button',{directives:[{name:"permission",rawName:"v-permission",value:(['update']),expression:"['update']"}],staticClass:"border border-blue-600 text-sm text-blue-600 px-2 py-0.5 rounded duration-500 mr-3",on:{"click":function($event){return _vm.setServiceChatToBot()}}},[_vm._v(" 切換為機器人客服 ")]):_vm._e()]),_c('div',{ref:"chatContent",staticClass:"md:max-h-[500px] max-h-screen overflow-y-auto md:min-h-[500px] min-h-screen md:pb-5 pb-[250px] relative"},_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,staticClass:"flex items-start mt-3",class:message.userId === _vm.selectUserId
                    ? 'justify-end'
                    : 'justify-start pl-2',attrs:{"id":message.key}},[_c('div',{on:{"click":_vm.goProfile}},[(message.userId !== _vm.selectUserId)?_c('Avatar',{attrs:{"backgroundImg":_vm.receiverInfo.userData.avatar,"size":['w-6', 'h-6', 'flex-none']}}):_vm._e()],1),(message.userId === _vm.selectUserId)?_c('div',{staticClass:"self-end flex-1 mx-2 text-xs text-right text-gray-300"},[(_vm.receiverInfo.readedAt > message.createdAt)?_c('div',[_vm._v(" 已讀 ")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm._f("formatMonthAndDayTime")(message.createdAt)))])]):_vm._e(),(_vm.orderTypes.includes(message.type))?_c('OrderMessage',{attrs:{"message":message}}):_vm._e(),(message.type === 'keywordWarning')?_c('div',{staticClass:"px-5 py-2 mx-auto text-xs text-center text-white bg-red-500 rounded-lg"},[_c('span',[_vm._v(_vm._s(message.content))])]):_vm._e(),(message.type === 'vorder')?_c('VorderMessage',{attrs:{"message":message}}):_vm._e(),(message.type === undefined)?_c('div',{class:message.userId === _vm.selectUserId
                        ? 'whitespace-pre-line bg-blue-500 text-white mr-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'
                        : 'whitespace-pre-line bg-white ml-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'},[_vm._v(" "+_vm._s(message.content)+" ")]):_vm._e(),(message.type === 'bot')?_c('div',{class:message.userId === _vm.selectUserId
                        ? 'bg-blue-500 text-white mr-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'
                        : 'bg-white ml-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'},[_c('div',[_c('div',{staticClass:"whitespace-pre-line"},[_vm._v(" "+_vm._s(message.content)+" ")]),(!_vm.$isEmpty(message.buttons))?_c('div',_vm._l((message.buttons),function(item,key){return _c('button',{key:key,staticClass:"mt-2 bg-white text-orange-600 px-3 py-2 rounded-full max-w-[150px] min-w-[150px] text-sm block mx-auto disabled:cursor-not-allowed",attrs:{"disabled":true}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0):_vm._e()])]):_vm._e(),(message.type === 'image')?_c('div',{staticClass:"leading-none",class:message.userId === _vm.selectUserId
                        ? 'whitespace-pre-line bg-blue-500 text-white rounded-lg mr-2'
                        : 'whitespace-pre-line bg-white rounded-lg ml-2'},[_c('silent-box',{attrs:{"thumbnailHeight":"100","thumbnailWidth":"100","image":{ src: message.content }},scopedSlots:_vm._u([{key:"silentbox-item",fn:function({ silentboxItem }){return [_c('img',{staticClass:"max-w-[100px] max-h-[100px] rounded-lg p-1",attrs:{"src":silentboxItem.src,"alt":""}})]}}],null,true)})],1):_vm._e(),(message.userId !== _vm.selectUserId)?_c('span',{staticClass:"self-end mx-2 text-xs text-gray-300"},[_vm._v(_vm._s(_vm._f("formatMonthAndDayTime")(message.createdAt)))]):_vm._e()],1)}),0),_c('Dialog',{attrs:{"showDialog":_vm.showDialog,"customClass":'md:left-1/4 top-1/4'},on:{"onCloseDialog":_vm.closeConfirm}},[_c('div',{staticClass:"md:w-[50%] w-full md:p-10 px-5 py-3 bg-white rounded"},[_c('p',[_vm._v(" 聊天室一但被刪除將無法恢復聊天記錄！ "),_c('br'),_vm._v("是否刪除聊天室？ ")]),_c('div',{staticClass:"flex justify-center pt-2 mt-3 border-t border-gray-100"},[_c('button',{staticClass:"border border-black text-black px-3 py-0.5 rounded text-sm mr-5",on:{"click":function($event){$event.preventDefault();return _vm.closeConfirm.apply(null, arguments)}}},[_vm._v(" 取消 ")]),_c('button',{staticClass:"bg-orange-600 text-white px-3 py-0.5 rounded text-sm",on:{"click":function($event){$event.preventDefault();return _vm.deleteChatRoomMessages()}}},[_vm._v(" 確認 ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }