/**
 * firebaseChatMessages: 創建聊天內容
 */
import { firebaseChatMessages } from "@/service/firebaseChatSet";
// 導入 firestore 連接資料庫機制
import { db } from "@/plugins/firebase";
/**
 * firebaseSetReadedTime: 更新聊天對象已讀訊息時間
 * firebaseSetUnReadCountAndLastMessage: 設定未讀訊息數量 以及最後一筆聊天內容
 * firebaseUpdateUserUnReadMessageCountByServiceChat: 更新登入者與客服聊天對象未讀訊息總計
 * firebaseUpdateUserUnReadMessageCount: 更新非客服使用者未讀訊息數量總計
 */
import {
    firebaseSetReadedTime,
    firebaseSetUnReadCountAndLastMessage,
    firebaseUpdateUserUnReadMessageCountByServiceChat,
    firebaseUpdateUserUnReadMessageCount,
} from "@/service/firestoreService";
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("utilityStore", ["uiSwitchSettingDatas"]),
    },

    methods: {
        /**
         * 確認發送對象 因為有 cityAi 因素 因此需要判斷 route name 來對應使用者 id
         */
        checkSendUserId() {
            // 判斷是否為客服聊天室 非 cityAi
            if (this.$route.name === this.checkRouteName) {
                return {
                    userId: this.serviceChatId,
                    receiveUserId: this.currentReceiveUserId,
                };
            } else {
                return {
                    userId: this.currentCityAiProviderId,
                    receiveUserId: this.currentCityAiReceiverId,
                };
            }
        },
        //上傳圖片超過限制數量
        imageOverLimit() {
            this.$message({
                type: "error",
                message: "超過上傳圖片上限",
            });
        },
        /**
         * 圖片變更時
         * @param { type File(檔案) } file 上傳檔案資料
         * @param { type Array(陣列) } fileList 檔案列表資料
         * @returns
         */
        handleFileChange(file) {
            if (
                file.size >
                (this.uiSwitchSettingDatas.upload_photo_size ??
                    Number(process.env.VUE_APP_UPLOAD_PHOTO_SIZE)) *
                    1024 *
                    1024
            ) {
                // 清空檔案上傳列表
                this.fileList = [];
                this.$message({
                    type: "error",
                    message: `圖片尺寸不能大於 ${
                        this.uiSwitchSettingDatas.upload_photo_size ??
                        process.env.VUE_APP_UPLOAD_PHOTO_SIZE
                    }MB`,
                });
                return;
            }
            // 先檢查目前是否在 客服聊天室 或 cityai 聊天室 返回對應的 userId 與 receiveUserId
            const { userId, receiveUserId } = this.checkSendUserId();
            this.uploadAttachments(file, userId, receiveUserId);
        },
        /**
         * 取得聊天對象未讀數量
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對象 id
         */
        async getReveiveUserUnReadTotal(userId, receiveUserId) {
            try {
                const data = await db()
                    .doc(`chat_rooms/${receiveUserId}/users/${userId}`)
                    .get("value");
                // 判斷沒有找到資料時給予預設值 0
                if (!data.exists) {
                    return 0;
                }
                return this.$isEmpty(data.data().unReadMessageCount)
                    ? 0
                    : data.data().unReadMessageCount;
            } catch (err) {
                console.log(err);
            }
        },
        /**
         * 檔案上傳
         * @param { type File(檔案) } file 檔案資料
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對象 id
         */
        async uploadAttachments(file, userId, receiveUserId) {
            try {
                const { data } = await this.$api.ChatUploadAttachmentsApi(file);
                // 發送至firebase 資料
                const sendData = {
                    userId: userId,
                    type: "image",
                    content: data.url,
                    ownerId: this.user.id,
                };
                // 取消讀取歷史訊息事件
                this.$emit("update:isReadHistory", false);
                // 清空檔案列表
                this.fileList = [];
                // 計算未讀訊息
                let count = await this.getReveiveUserUnReadTotal(
                    userId,
                    receiveUserId
                );
                count += 1;
                // 發送至對方聊天室
                firebaseChatMessages(sendData, receiveUserId, userId);
                // 發送至自己聊天室
                firebaseChatMessages(sendData, userId, receiveUserId);
                // 設定 未讀訊息數量 與最後一筆 聊天室內容 (聊天對象)
                await firebaseSetUnReadCountAndLastMessage(
                    count,
                    this.$moment().valueOf(),
                    "傳送一張照片",
                    receiveUserId,
                    userId
                );
                // 設定 未讀訊息數量 與最後一筆 聊天室內容 (自己)
                await firebaseSetUnReadCountAndLastMessage(
                    0,
                    this.$moment().valueOf(),
                    "傳送一張照片",
                    userId,
                    receiveUserId
                );
                // 更新聊天對象已讀訊息時間
                await firebaseSetReadedTime(receiveUserId, userId);
                // 更新聊天對象對於客服未讀訊息數量總計
                await firebaseUpdateUserUnReadMessageCountByServiceChat(
                    receiveUserId
                );
                // 不是客服聊天室時才觸發 也就是說會在 cityAi 時觸發 因為 cityAi 只會在發送訊息時更新對方已讀時間
                if (this.$route.name !== this.checkRouteName) {
                    // 更新 chat_rooms 中指定對象 所有未讀訊息數量
                    await firebaseUpdateUserUnReadMessageCount(receiveUserId);
                    await firebaseUpdateUserUnReadMessageCount(userId);
                }
            } catch (err) {
                // 清空檔案列表
                this.fileList = [];
                this.$message({
                    type: "error",
                    message: "上傳檔案失敗",
                });
            }
        },
    },
};
