<template>
    <!-- 發送訊息功能 -->
    <div
        v-if="currentReceiveUserId !== 'all'"
        class="fixed bottom-0 left-0 flex items-center w-full px-3 py-3 mb-0 bg-white rounded-b-lg md:mb-0 md:static"
    >
        <!-- 上傳圖片功能 -->
        <el-upload
            id="chatUpload"
            ref="upload"
            list-type="picture-card"
            :on-change="handleFileChange"
            :auto-upload="false"
            :file-list="fileList"
            :limit="1"
            action=""
            :on-exceed="imageOverLimit"
            class="flex items-center flex-grow-0"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
        >
            <img
                class="h-full mr-2 cursor-pointer"
                src="img/icons/web/chats/photo-gray.svg"
                alt=""
            />
        </el-upload>
        <!-- <img class="mr-2 cursor-pointer "
                             src="~assets/img/icons/web/chats/location-gray.svg"
                             alt=""> -->
        <textarea
            v-model="message"
            class="text-sm pl-3 pt-1 rounded-full border flex-1 border-gray-100 mr-2 min-h-[30px] w-full"
            rows="auto"
            minrows="1"
            maxrows="5"
            @keyup.ctrl.enter="changeLine"
            @keydown.enter.prevent.exact="isSendMessage"
        ></textarea>
        <div class="px-3 cursor-pointer md:px-5" @click.prevent="isSendMessage">
            <img src="img/icons/web/chats/send-gray.svg" alt="" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
// 導入 firestore 連接資料庫機制
import { db } from "@/plugins/firebase";
/**
 * firebaseChatMessages: 創建聊天內容
 */
import { firebaseChatMessages } from "@/service/firebaseChatSet";
/**
 * firebaseSetUnReadCountAndLastMessage: 設定未讀訊息數量 以及最後一筆聊天內容
 * firebaseUpdateUserUnReadMessageCountByServiceChat: 更新登入者與客服聊天對象未讀訊息總計
 * firebaseSetReadedTime: 更新聊天對象已讀訊息時間
 * firebaseUpdateUserUnReadMessageCount: 更新非客服使用者未讀訊息數量總計
 */
import {
    firebaseSetUnReadCountAndLastMessage,
    firebaseUpdateUserUnReadMessageCountByServiceChat,
    firebaseSetReadedTime,
    firebaseUpdateUserUnReadMessageCount,
} from "@/service/firestoreService";
// 導入上傳圖片方法
import uploadMixin from "../service/FirestoreUploadMixin";
export default {
    mixins: [uploadMixin],
    props: {
        templateMessage: {
            type: String,
            default: "",
        },
    },
    computed: {
        ...mapState("userStore", ["user"]),
        ...mapState("firestoreChatStore", ["currentReceiveUserId"]),
        ...mapState("cityAiStore", [
            "currentCityAiProviderId",
            "currentCityAiReceiverId",
        ]),
    },
    data() {
        return {
            // 上傳檔案列表
            fileList: [],
            // 訊息輸入匡
            message: "",
            // 判斷是否為系統客服
            serviceChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
            // 用來判斷哪些頁面才需執行的程式 或者 取得不同的資料
            checkRouteName: "firestore-chats",
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        /**
         * 點擊 ctrl + enter 時實現換行功能
         * @param { type Event } 瀏覽器的事件
         */
        changeLine() {
            this.message = this.message + "\n";
        },
        /**
         * 確認發送對象 因為有 cityAi 因素 因此需要判斷 route name 來對應使用者 id
         */
        checkSendUserId() {
            // 判斷是否為客服聊天室 非 cityAi
            if (this.$route.name === this.checkRouteName) {
                return {
                    userId: this.serviceChatId,
                    receiveUserId: this.currentReceiveUserId,
                };
            } else {
                return {
                    userId: this.currentCityAiProviderId,
                    receiveUserId: this.currentCityAiReceiverId,
                };
            }
        },
        /**
         * 發送訊息
         * @param { type Event } 瀏覽器的事件
         */
        async isSendMessage(e) {
            // 先檢查目前是否在 客服聊天室 或 cityai 聊天室 返回對應的 userId 與 receiveUserId
            const { userId, receiveUserId } = this.checkSendUserId();
            // 判斷是否在選字中 選字狀態下不往下執行
            if (e.isComposing) {
                return;
            }
            // 判斷沒有輸入訊息時 不能做送出
            if (this.message.length < 1) {
                return;
            }
            // const data = {
            //     content: this.message,
            //     userId: userId,
            //     ownerId: this.user.id,
            // };
            // let count = 0;
            // try {
            //     // 計算未讀訊息
            //     count = await this.getReveiveUserUnReadTotal(userId, receiveUserId);
            //     count += 1;
            // } catch (err) {
            //     console.log(err);
            // }

            // 發送訊息時非閱讀歷史訊息狀態 因此將此值改為 false
            this.$emit("update:isReadHistory", false);
            if (this.$route.name === this.checkRouteName) {
                // 發送客服訊息
                await this.sendMessageByNestjsApi({
                    receiveUserId,
                    message: this.message,
                });
            } else {
                await this.sendCityAIMessageByNestjsApi({
                    loginUserId: userId,
                    receiveUserId,
                    message: this.message,
                });
            }
            // // 將訊息發送給對方 firebase 聊天室資料庫
            // await this.receiveMessage(data, count, userId, receiveUserId);
            // // 發送訊息給自己 firebase 聊天室資料庫
            // await this.sendMessage(data, userId, receiveUserId);
            // 清空訊息輸入框
            this.clearnMessage();
            // 發送訊息完畢後將視窗滾到最底部
            this.$nextTick(() => {
                if (this.$refs.chatContent !== undefined) {
                    this.$refs.chatContent.scrollTo(
                        0,
                        this.$refs.chatContent.scrollHeight
                    );
                }
            });
        },
        /**
         * 取得聊天對象未讀數量
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對象 id
         */
        async getReveiveUserUnReadTotal(userId, receiveUserId) {
            try {
                const data = await db()
                    .doc(`chat_rooms/${receiveUserId}/users/${userId}`)
                    .get("value");
                // 判斷沒有找到資料時給予預設值 0
                if (!data.exists) {
                    return 0;
                }
                return this.$isEmpty(data.data().unReadMessageCount)
                    ? 0
                    : data.data().unReadMessageCount;
            } catch (err) {
                console.log(err);
            }
        },
        /**
         * 接收訊息對象
         * @param { type Object(物件) } data 聊天室內容
         * @param { type Number(數量) } unReadCount 未讀訊息數量
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對象 id
         */
        async receiveMessage(data, unReadCount, userId, receiveUserId) {
            // 發送聊天室訊息
            await firebaseChatMessages(data, receiveUserId, userId);
            // 不是客服聊天室時才觸發 也就是說會在 cityAi 時觸發 因為 cityAi 只會在發送訊息時更新對方已讀時間
            if (this.$route.name !== this.checkRouteName) {
                // 更新聊天對象已讀訊息時間
                await firebaseSetReadedTime(receiveUserId, userId);
            }
            // 設定 未讀訊息數量 與最後一筆 聊天室內容
            await firebaseSetUnReadCountAndLastMessage(
                unReadCount,
                this.$moment().valueOf(),
                data.content,
                receiveUserId,
                userId
            );
            // 不是客服聊天室時才觸發 也就是說會在 cityAi 時觸發
            if (this.$route.name !== this.checkRouteName) {
                // 更新 chat_rooms 中指定對象 所有未讀訊息數量
                await firebaseUpdateUserUnReadMessageCount(receiveUserId);
            }
        },
        /**
         * 發送訊息人員
         * @param { type Object(物件) } data 聊天室內容
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對象 id
         */
        async sendMessage(data, userId, receiveUserId) {
            // 發送聊天室訊息
            await firebaseChatMessages(data, userId, receiveUserId);
            // 更新登入者與客服聊天未讀訊息數量總計
            await firebaseUpdateUserUnReadMessageCountByServiceChat(receiveUserId);
            // 設定 未讀訊息數量 與最後一筆 聊天室內容
            await firebaseSetUnReadCountAndLastMessage(
                0,
                this.$moment().valueOf(),
                data.content,
                userId,
                receiveUserId
            );
            // 不是客服聊天室時才觸發 也就是說會在 cityAi 時觸發
            if (this.$route.name !== this.checkRouteName) {
                // 更新 chat_rooms 中指定對象 所有未讀訊息數量
                await firebaseUpdateUserUnReadMessageCount(userId);
            }
        },
        // 發送訊息 api
        async sendMessageByNestjsApi({ receiveUserId, message }) {
            try {
                await this.$api.ChatSendMessageApi({ receiveUserId, message });
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        // 發送訊息 api
        async sendCityAIMessageByNestjsApi({ loginUserId, receiveUserId, message }) {
            try {
                await this.$api.ChatSendCityAIMessageApi({
                    loginUserId,
                    receiveUserId,
                    message,
                });
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        /**
         * 清空輸入匡訊息內容
         */
        clearnMessage() {
            this.message = "";
        },
    },
    watch: {
        templateMessage(val) {
            if (!this.$isEmpty(val)) {
                this.message = val;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep #chatUpload {
    .el-upload-list {
        @apply hidden p-0 m-0;
    }
}
::v-deep #chatUpload {
    .el-upload--picture-card {
        @apply w-auto h-auto border-none;
    }
}
</style>
