<template>
    <div>
        <div
            v-if="orderCreateOrUpdateOrDemandTypes.includes(message.type)"
            class="px-3 py-1.5 rounded-lg shadow-xl text-sm cursor-pointer"
            :class="
                message.userId === user.banana_id
                    ? 'mr-2 bg-blue-500 text-white'
                    : 'ml-2 bg-white'
            "
        >
            <div class="flex">
                <span
                    v-if="message.type !== 'acceptDatingDemandEnrollment'"
                    class="flex-1"
                    >已{{
                        message.type === "updateDating" ? "修改" : "發起"
                    }}預訂，內容如下：</span
                >
                <span v-else class="flex-1"
                    >已接受即刻快閃報名，內容如下：</span
                >
                <img
                    class="w-[20px]"
                    :src="
                        message.userId === user.banana_id
                            ? 'img/icons/web/chats/click-finger-black.svg'
                            : 'img/icons/web/chats/click-finger-white.svg'
                    "
                    alt=""
                />
            </div>
            <p>開始時間：{{ message.startedAt | formatDateTime }}</p>
            <p>結束時間：{{ message.endedAt | formatDateTime }}</p>
            <p>
                會面地點：{{ $checkLang(langDatas, "areas", message.district) }}
                |
                {{ message.location }}
            </p>
            <p>
                出席費用：$ {{ message.price | formatCurrency
                }}<span v-if="message.paidBy === 1">(現金付款)</span>
            </p>
        </div>
        <!-- 新增或更新訂單 -->
        <div
            v-if="extendOrderTypes.includes(message.type)"
            class="px-3 py-1.5 rounded-lg shadow-xl text-sm cursor-pointer"
            :class="
                message.userId === user.banana_id
                    ? 'mr-2 bg-blue-500 text-white'
                    : 'ml-2 bg-white'
            "
        >
            <div class="flex">
                <span class="flex-1"
                    >{{
                        message.type === "updateExtendOrder" ? "修改" : "提出"
                    }}續約，內容如下：</span
                >
                <img
                    class="w-[20px]"
                    :src="
                        message.userId === user.banana_id
                            ? 'img/icons/web/chats/click-finger-black.svg'
                            : 'img/icons/web/chats/click-finger-white.svg'
                    "
                    alt=""
                />
            </div>
            <p>續約時間：{{ message.duration }} 小時</p>
            <p>結束時間：{{ message.endedAt | formatDateTime }}</p>
            <p>出席費用：$ {{ message.price | formatCurrency }}</p>
        </div>
        <!-- 續約單取消 或 核准 -->
        <div
            v-if="cancelOrAcceptExtendOrderTypes.includes(message.type)"
            class="px-3 py-1.5 rounded-lg shadow-xl text-sm mt-3 cursor-pointer"
            :class="
                message.userId === user.banana_id
                    ? 'mr-2 bg-blue-500 text-white'
                    : 'ml-2 bg-white'
            "
        >
            <div class="flex">
                <span class="flex-1">{{ message.type | systemString }}</span>
                <img
                    class="w-[20px]"
                    :src="
                        message.userId === user.banana_id
                            ? 'img/icons/web/chats/click-finger-black.svg'
                            : 'img/icons/web/chats/click-finger-white.svg'
                    "
                    alt=""
                />
            </div>
        </div>
        <!-- 約會單取消 或 核准 -->
        <div
            v-if="cancelOrAcceptOrderTypes.includes(message.type)"
            class="px-3 py-1.5 rounded-lg shadow-xl text-sm cursor-pointer"
            :class="
                message.userId === user.banana_id
                    ? 'mr-2 bg-blue-500 text-white'
                    : 'ml-2 bg-white'
            "
        >
            <div class="flex">
                <span class="flex-1">{{ message.type | systemString }}</span>
                <img
                    class="w-[20px]"
                    :src="
                        message.userId === user.banana_id
                            ? 'img/icons/web/chats/click-finger-black.svg'
                            : 'img/icons/web/chats/click-finger-white.svg'
                    "
                    alt=""
                />
            </div>
            <p
                v-if="message.type !== 'acceptOrderByProvider'"
                class="break-all"
            >
                {{
                    message.type === "cancelOrderByProvider" ? "婉拒" : "取消"
                }}原因：{{ message.note }}
            </p>
        </div>
        <!-- 完成約會訂單 -->
        <!-- <div class="px-3 py-1.5 rounded-lg shadow-xl text-sm mt-3"
             @click="openDrawer(message.orderId)"
             :class="message.userId === user.banana_id ? 'mr-2 bg-blue-500 text-white' : 'ml-2 bg-white'"
             v-if="finishOrderTypes.includes(message.type)">
            <p>
                {{ message.type | systemString }}
            </p>
        </div> -->
        <!-- 系統發起訊息 (開始約會 ｜ 結束約會 ｜ 系統自動婉拒) -->
        <div
            v-if="systemOrderTypes.includes(message.type)"
            class="px-3 py-1.5 rounded-lg shadow-xl text-sm mt-3 whitespace-pre cursor-pointer"
            :class="
                message.userId === user.banana_id
                    ? 'mr-2 bg-blue-500 text-white'
                    : 'ml-2 bg-white'
            "
        >
            <div class="flex items-start">
                <span class="flex-1">{{ message.type | systemString }}</span>
                <img
                    class="w-[20px]"
                    :src="
                        message.userId === user.banana_id
                            ? 'img/icons/web/chats/click-finger-black.svg'
                            : 'img/icons/web/chats/click-finger-white.svg'
                    "
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    components: {},
    props: {
        message: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    filters: {
        /**
         * 系統通知字串
         */
        systemString(val) {
            switch (val) {
                case "cancelExtendOrderByUser":
                    return "取消續約";
                case "cancelExtendOrderByProvider":
                    return "婉拒續約";
                case "acceptExtendOrderByProvider":
                    return "接受續約";
                case "cancelOrderByProvider":
                    return "婉拒預訂";
                case "cancelOrderByUser":
                    return "取消預訂";
                case "cancelOrderBySystem":
                    return "系統自動婉拒預訂";
                case "cancelOrderByUserAndFeePay":
                    return "臨時取消預訂";
                case "acceptOrderByProvider":
                    return "接受預訂";
                case "startDating":
                    return `預訂的行程開始囉！
讓我們在城市中追求美好的時刻`;
                case "completeDating":
                    return `您的行程結束囉！
歡迎幫我評分，謝謝您 !
祝福您有個美好的一天`;
                default:
                    break;
            }
        },
    },
    computed: {
        ...mapState("userStore", ["user"]),
        ...mapState("i18nStore", ["langDatas"]),
    },
    data() {
        return {
            // 訂單資料
            order: {},
            // 判斷是否顯示 抽屜彈窗組件
            showDrawer: false,
            // 續約單type
            extendOrderTypes: ["updateExtendOrder", "createExtendOrder"],
            // 取消續約單獲核准type
            cancelOrAcceptExtendOrderTypes: [
                "cancelExtendOrderByUser",
                "cancelExtendOrderByProvider",
                "acceptExtendOrderByProvider",
            ],
            // 取消續約單獲核准type
            cancelOrAcceptOrderTypes: [
                "cancelOrderByUser",
                "cancelOrderByUserAndFeePay",
                "cancelOrderByProvider",
                "acceptOrderByProvider",
            ],
            // 完成訂單
            // finishOrderTypes: ["completeDating"],
            // 發起預訂 跟 修改預訂
            orderCreateOrUpdateOrDemandTypes: [
                "createDating",
                "updateDating",
                "acceptDatingDemandEnrollment",
            ],
            // 系統type
            systemOrderTypes: [
                "startDating",
                "completeDating",
                "cancelOrderBySystem",
            ],
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
    },
};
</script>
