<template>
    <div>
        <!-- 聊天室聊天對象資料 -->
        <div class="flex items-center px-2 py-3 bg-white rounded-t-lg">
            <div v-if="isMobile" class="ml-5 mr-10">
                <button
                    class=""
                    @click.prevent="
                        $emit('update:showProviderReceivers', true);
                        $emit('update:showChatRooms', true);
                        $emit('update:showChats', false);
                        setCurrentBreadcrumbByReceiver(null);
                    "
                >
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </button>
            </div>
            <div
                @click="
                    goProfile(
                        receiverInfo.userData.role,
                        receiverInfo.userData.id
                    )
                "
            >
                <Avatar
                    :backgroundImg="receiverInfo.userData.avatar"
                    :size="['w-10', 'h-10', 'flex-none']"
                />
            </div>
            <span class="flex-1 ml-2 text-blue-500">
                {{ receiverInfo.userData.name }}
            </span>
            <!-- 此按鈕只會在 客服聊天室出現 cityAi 不出現 -->
            <button
                v-if="receiverInfo.isBot && $route.name === checkRouteName"
                v-permission="['update']"
                class="border border-orange-600 text-sm text-orange-600 px-2 py-0.5 rounded duration-500 mr-3"
                @click="setServiceChatToReal()"
            >
                切換為真人客服
            </button>
            <!-- 此按鈕只會在 客服聊天室出現 cityAi 不出現 -->
            <button
                v-if="!receiverInfo.isBot && $route.name === checkRouteName"
                v-permission="['update']"
                class="border border-blue-600 text-sm text-blue-600 px-2 py-0.5 rounded duration-500 mr-3"
                @click="setServiceChatToBot()"
            >
                切換為機器人客服
            </button>
            <!-- <el-dropdown trigger="click"
                         @command="handleCommand">
                <span class="mr-3 el-dropdown-link">
                    <span><i class="fas fa-ellipsis-v"></i></span>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="seeUserProfile">查看個人頁面</el-dropdown-item>
                    <el-dropdown-item command="deleteChatRoomMessages">刪除聊天室</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown> -->
        </div>
        <!-- 聊天室內容 -->
        <div
            ref="chatContent"
            class="md:max-h-[500px] max-h-screen overflow-y-auto md:min-h-[500px] min-h-screen md:pb-5 pb-[250px] relative"
        >
            <div
                v-for="(message, index) in messages"
                :id="message.key"
                :key="index"
                class="flex items-start mt-3"
                :class="
                    message.userId === selectUserId
                        ? 'justify-end'
                        : 'justify-start pl-2'
                "
            >
                <div @click="goProfile">
                    <Avatar
                        v-if="message.userId !== selectUserId"
                        :backgroundImg="receiverInfo.userData.avatar"
                        :size="['w-6', 'h-6', 'flex-none']"
                    />
                </div>
                <!-- 聊天對象顯示日期與已讀方式 -->
                <div
                    v-if="message.userId === selectUserId"
                    class="self-end flex-1 mx-2 text-xs text-right text-gray-300"
                >
                    <!-- 已讀 -->
                    <div v-if="receiverInfo.readedAt > message.createdAt">
                        已讀
                    </div>
                    <!-- 日期 -->
                    <div>{{ message.createdAt | formatMonthAndDayTime }}</div>
                </div>
                <!-- 預訂單顯示方式 -->
                <OrderMessage
                    v-if="orderTypes.includes(message.type)"
                    :message="message"
                />
                <!-- 警告文字 -->
                <div
                    v-if="message.type === 'keywordWarning'"
                    class="px-5 py-2 mx-auto text-xs text-center text-white bg-red-500 rounded-lg"
                >
                    <span>{{ message.content }}</span>
                </div>
                <!-- 虛擬單顯示方式 -->
                <VorderMessage
                    v-if="message.type === 'vorder'"
                    :message="message"
                />
                <!-- 一般聊天內容 -->
                <div
                    v-if="message.type === undefined"
                    :class="
                        message.userId === selectUserId
                            ? 'whitespace-pre-line bg-blue-500 text-white mr-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'
                            : 'whitespace-pre-line bg-white ml-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'
                    "
                >
                    {{ message.content }}
                </div>
                <!-- 與機器人的聊天內容 -->
                <div
                    v-if="message.type === 'bot'"
                    :class="
                        message.userId === selectUserId
                            ? 'bg-blue-500 text-white mr-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'
                            : 'bg-white ml-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'
                    "
                >
                    <div>
                        <div class="whitespace-pre-line">
                            {{ message.content }}
                        </div>
                        <div v-if="!$isEmpty(message.buttons)">
                            <button
                                v-for="(item, key) in message.buttons"
                                :key="key"
                                class="mt-2 bg-white text-orange-600 px-3 py-2 rounded-full max-w-[150px] min-w-[150px] text-sm block mx-auto disabled:cursor-not-allowed"
                                :disabled="true"
                            >
                                {{ item.text }}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- 圖片內容 -->
                <div
                    v-if="message.type === 'image'"
                    :class="
                        message.userId === selectUserId
                            ? 'whitespace-pre-line bg-blue-500 text-white rounded-lg mr-2'
                            : 'whitespace-pre-line bg-white rounded-lg ml-2'
                    "
                    class="leading-none"
                >
                    <silent-box
                        thumbnailHeight="100"
                        thumbnailWidth="100"
                        :image="{ src: message.content }"
                    >
                        <template #silentbox-item="{ silentboxItem }">
                            <img
                                class="max-w-[100px] max-h-[100px] rounded-lg p-1"
                                :src="silentboxItem.src"
                                alt=""
                            />
                        </template>
                    </silent-box>
                </div>
                <!-- 自己的聊天內容日期顯示格式 -->
                <span
                    v-if="message.userId !== selectUserId"
                    class="self-end mx-2 text-xs text-gray-300"
                    >{{ message.createdAt | formatMonthAndDayTime }}</span
                >
            </div>
        </div>
        <!-- 刪除聊天室時的彈窗 -->
        <Dialog
            :showDialog="showDialog"
            :customClass="'md:left-1/4 top-1/4'"
            @onCloseDialog="closeConfirm"
        >
            <div class="md:w-[50%] w-full md:p-10 px-5 py-3 bg-white rounded">
                <p>
                    聊天室一但被刪除將無法恢復聊天記錄！ <br />是否刪除聊天室？
                </p>
                <div
                    class="flex justify-center pt-2 mt-3 border-t border-gray-100"
                >
                    <button
                        class="border border-black text-black px-3 py-0.5 rounded text-sm mr-5"
                        @click.prevent="closeConfirm"
                    >
                        取消
                    </button>
                    <button
                        class="bg-orange-600 text-white px-3 py-0.5 rounded text-sm"
                        @click.prevent="deleteChatRoomMessages()"
                    >
                        確認
                    </button>
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
// 導入 firebase 連接資料庫機制
import { firebaseConnectRef } from "@/plugins/firebase";
/**
 * firebaseSetReadedTime: 更新聊天對象已讀訊息時間
 * firebaseSetServiceChatToReal: 切換為真人客服
 * firebaseSetServiceChatToBot: 切換為機器人客服
 * firebaseUnReadCountAndLastMessage: 更新聊天室 未讀訊息數量 與最後一筆聊天內容
 */
import {
    firebaseSetReadedTime,
    firebaseSetServiceChatToReal,
    firebaseSetServiceChatToBot,
    firebaseUnReadCountAndLastMessage,
} from "@/service/firestoreService";

// 導入彈窗組件
import Dialog from "@/components/Dialog.vue";
// 導入大頭照組件
import Avatar from "@/components/Avatar.vue";
// 導入系統訊息組件
import OrderMessage from "./FirestoreOrderMessage.vue";
// 導入系統訊息組件
import VorderMessage from "./FirestoreVorderMessage.vue";

export default {
    components: {
        Dialog,
        Avatar,
        OrderMessage,
        VorderMessage,
    },
    props: {
        // 判斷是否為正在讀取歷史訊息
        isReadHistory: {
            type: Boolean,
            default: false,
        },
        // 判斷是否顯示服務商聊天對象
        showProviderReceivers: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState("userStore", ["user", "isProvider"]),
        ...mapState("firestoreChatStore", [
            "currentReceiveUserId",
            "currentUserInfo",
        ]),
        ...mapState("cityAiStore", [
            "currentCityAiProviderId",
            "currentCityAiReceiverId",
            "currentCityAiReceiverUserInfo",
        ]),
        // 顯示聊天對象資訊
        receiverInfo() {
            // 判斷為客服聊天室時 使用對應客服聊天室 user 資料
            if (this.$route.name === this.checkRouteName) {
                return this.currentUserInfo;
            } else {
                // city ai 聊天室使用
                return this.currentCityAiReceiverUserInfo;
            }
        },
        selectUserId() {
            // 判斷為客服聊天室時 使用對應客服聊天室 user 資料
            if (this.$route.name === this.checkRouteName) {
                return this.serviceChatId;
            } else {
                return this.currentCityAiProviderId;
            }
        },
    },
    data() {
        return {
            // 約會訂單 type
            orderTypes: [
                "extendDating",
                "createDating",
                "updateDating",
                "updateExtendOrder",
                "createExtendOrder",
                "cancelExtendOrderByUser",
                "cancelExtendOrderByProvider",
                "acceptExtendOrderByProvider",
                "cancelOrderByUser",
                "cancelOrderByUserAndFeePay",
                "cancelOrderByProvider",
                "cancelOrderBySystem",
                "acceptOrderByProvider",
                "completeDating",
                "startDating",
                "acceptDatingDemandEnrollment",
            ],
            // 判斷是否顯示刪除聊天室警告彈窗
            showDialog: false,
            // 聊天室訊息
            messages: [],
            // 聊天室單頁資料筆數
            messageLimit: 35,
            // 最後一個 key
            lastMessageKey: null,
            // 聊天室查看歷史紀錄最大比數
            maxHistoryMessageLimit: parseInt(
                process.env.VUE_APP_CHATS_MESSAGE_LIMIT
            ),
            // 判斷是否載入歷史資料中
            loadingHistoryMessage: false,
            // 聊天對象名稱與大頭照
            chatUser: { name: "", avatar: "" },
            // 判斷是否為系統客服
            serviceChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
            // 用來判斷哪些頁面才需執行的程式 或者 取得不同的資料
            checkRouteName: "firestore-chats",
        };
    },
    methods: {
        ...mapMutations("cityAiStore", ["setCurrentBreadcrumbByReceiver"]),
        /**
         * 取得聊天訊息列表
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對象 id
         */
        getMessages(userId, receiveUserId) {
            // 執行滾動監聽
            this.windowSrcoll();
            firebaseConnectRef("chats")
                .child(userId)
                .child(receiveUserId)
                .orderByKey()
                .limitToLast(this.messageLimit)
                .on("value", async (snapshot) => {
                    // 判斷firebase傳入的聊天對象key 不等同於當前聊天對象時 不執行
                    if (snapshot.key !== receiveUserId) {
                        return;
                    }
                    // 判斷是否有資料
                    if (snapshot.val() !== null) {
                        // 將物件資料整理成陣列格式
                        const messages = Object.keys(snapshot.val()).map(
                            (objKey) => {
                                const obj = snapshot.val()[objKey];
                                obj.key = objKey;
                                return obj;
                            }
                        );
                        // 判斷是否為讀取歷史資料
                        if (this.isReadHistory) {
                            /**
                             * 將目前陣列中的聊天資料 與 firebase中最新的聊天資料做比對 看是否有一樣的
                             * 如果沒有一樣的一樣的資料時為 undefined 並將資料新增進入陣列中
                             * 此判斷最主要是避免重複資料塞進陣列中
                             */
                            const message = this.messages.find(
                                (item) =>
                                    item.key ===
                                    messages[messages.length - 1].key
                            );
                            if (message === undefined) {
                                this.messages.push(
                                    messages[messages.length - 1]
                                );
                            }
                        } else {
                            this.messages = messages;
                        }
                        // 記錄聊天記錄key
                        this.lastMessageKey = messages[0].key;
                        // 發送訊息完畢後將視窗滾到最底部
                        this.$nextTick(async () => {
                            // 判斷是否為讀取歷史訊息狀態 以及為客服聊天室時才運行 cityAi 聊天室時不執行
                            if (
                                !this.isReadHistory &&
                                this.$route.name === this.checkRouteName
                            ) {
                                // 更新聊天對象已讀訊息時間
                                await firebaseSetReadedTime(
                                    this.currentReceiveUserId,
                                    this.serviceChatId
                                );
                            }
                            if (this.$refs.chatContent !== undefined) {
                                this.$refs.chatContent.scrollTo(
                                    0,
                                    this.$refs.chatContent.scrollHeight
                                );
                            }
                        });
                    } else {
                        // 如果聊天室沒有資料須清空 聊天室值
                        this.messages = [];
                    }
                });
        },
        // 取得分頁資料
        getMoreData() {
            // 抓取元素
            const dom = this.$refs.chatContent;
            // 判斷滾動條高度 減掉 視窗高度 在減掉滾動高度 大於 100時才觸發
            if (dom.scrollHeight - dom.clientHeight - dom.scrollTop >= 100) {
                // 判斷此時在觀看 歷史訊息 所以接收到新訊息時 不將視窗網下滾到底
                this.$emit("update:isReadHistory", true);
            }
            // console.log(dom.scrollTop, dom.clientHeight, dom.scrollHeight, dom.offsetTop);
            // dom.offsetTop 與瀏覽器最上方差的高度
            // dom.clientHeight 整個 div 可觀看的高度
            // dom.scrollHeight 整個 div 中內容高度
            // dom.scrollTop 滾動高度
            // 判斷載入訊息事件未結束時不作動
            if (this.loadingHistoryMessage) {
                return;
            }
            this.$nextTick(() => {
                //  當滾動高度 小於或等於 新人榜高度時做觸發
                if (dom.scrollTop <= 0) {
                    // 判斷為客服聊天室時 使用對應客服聊天室 userId
                    if (this.$route.name === this.checkRouteName) {
                        this.scrollUpGetPrevPagination(
                            this.serviceChatId,
                            this.currentReceiveUserId
                        );
                    } else {
                        // city ai 聊天室使用
                        this.scrollUpGetPrevPagination(
                            this.currentCityAiProviderId,
                            this.currentCityAiReceiverId
                        );
                    }
                }
            });
        },
        /**
         * 網上滾動時
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對象 id
         */
        async scrollUpGetPrevPagination(userId, receiveUserId) {
            if (this.lastMessageKey === null) {
                return;
            }
            // 判斷載入訊息事件未結束時不作動
            if (this.loadingHistoryMessage) {
                return;
            }
            console.log("網上滾動事件觸發2");
            // 網上滾動旋轉動畫結束
            this.loadingHistoryMessage = true;
            try {
                const snapshot = await firebaseConnectRef("chats")
                    .child(userId)
                    .child(receiveUserId)
                    .orderByKey()
                    .endBefore(this.lastMessageKey)
                    .limitToLast(this.messageLimit)
                    .once("value");
                // 判斷firebase傳入的聊天對象key 不等同於當前聊天對象時 不執行
                if (snapshot.key !== receiveUserId) {
                    // 網上滾動旋轉動畫結束
                    this.loadingHistoryMessage = false;
                    return;
                }
                // 判斷網上滾動時 最高可能讀取過去的訊息數量限制 超過數量 則不往下執行
                if (this.messages.length >= this.maxHistoryMessageLimit) {
                    console.log(
                        this.messages.length,
                        this.maxHistoryMessageLimit,
                        "is work 歷史訊息已達最大限制"
                    );
                    // 網上滾動旋轉動畫結束
                    this.loadingHistoryMessage = false;
                    return;
                }
                // 判斷是否有資料
                if (snapshot.val() !== null) {
                    // 將物件資料整理成陣列格式
                    const messages = Object.keys(snapshot.val()).map(
                        (objKey) => {
                            const obj = snapshot.val()[objKey];
                            obj.key = objKey;
                            return obj;
                        }
                    );
                    // 判斷最後一筆資料 key 與 最新資料的陣列中第一筆 key 相同 則不往下執行 代表沒有最新消息了
                    if (this.lastMessageKey === messages[0].key) {
                        console.log("網上滾動key已是最新");
                        // 網上滾動旋轉動畫結束
                        this.loadingHistoryMessage = false;
                        return;
                    }
                    this.messages.unshift(...messages);
                    // 記錄聊天記錄key
                    this.lastMessageKey = messages[0].key;
                    setTimeout(() => {
                        // 網上滾動旋轉動畫結束
                        this.loadingHistoryMessage = false;
                    }, 1500);
                    // 滾到頂部時再往下滾動多少距離
                    this.$nextTick(() => {
                        setTimeout(() => {
                            // 聊天室 dom
                            const dom = this.$refs.chatContent;
                            // 取得最後一筆訊息位置 dom
                            const lastElementId = document.getElementById(
                                messages[messages.length - 1].key
                            );
                            // 晚回滾動至載入歷史訊息前的最後一則訊息位置
                            dom.scrollTo({ top: lastElementId.offsetTop });
                        }, 1000);
                        // this.$refs.chatContent.scrollTo(0, this.$refs.chatContent.offsetTop * 2);
                    });
                } else {
                    // 網上滾動旋轉動畫結束
                    console.log("已無歷史訊息");
                    this.loadingHistoryMessage = false;
                }
            } catch (err) {
                console.log(err, "this.lastMessageKey", this.lastMessageKey);
                this.$message({
                    type: "error",
                    message: "取得歷史資料失敗",
                });
            }
        },
        // 觸發視窗滾動事件
        windowSrcoll() {
            this.$nextTick(() => {
                this.$refs.chatContent.addEventListener(
                    "scroll",
                    this.getMoreData
                );
            });
        },
        /**
         * 關閉刪除聊室警告彈窗
         */
        closeConfirm() {
            // 關閉彈窗
            this.showDialog = false;
            // 關閉彈窗時 取消讀取歷史訊息事件
            this.$emit("update:isReadHistory", false);
        },
        /**
         * 開啟警告刪除聊天室彈窗
         */
        showConfirm() {
            // 開啟彈窗
            this.showDialog = true;
        },
        /**
         * 點擊下拉選單時的事件 (三個點按鈕)
         * @param { type String(字串) } command 下拉選單選擇內容
         */
        handleCommand(command) {
            switch (command) {
                // 查看個人資料
                case "seeUserProfile":
                    break;
                // 刪除聊天室訊息
                case "deleteChatRoomMessages":
                    this.showConfirm();
                    break;
                default:
                    break;
            }
        },
        // 刪除聊天室訊息
        async deleteChatRoomMessages() {
            firebaseConnectRef("chats")
                .child(this.serviceChatId)
                .child(this.currentReceiveUserId)
                .remove();
            this.closeConfirm();
            await firebaseUnReadCountAndLastMessage(
                { message: "", unReadMessageCount: 0 },
                this.serviceChatId,
                this.currentReceiveUserId
            );
        },

        // 清空聊天室訊息
        clearMessages() {
            // 清空聊天室訊息
            this.messages = [];
            // 預設分頁定位key
            this.lastMessageKey = null;
            // 將聊天室卷軸滾動到底
            this.$refs.chatContent.scrollTo(
                0,
                this.$refs.chatContent.scrollHeight
            );
            // 關閉彈窗時 取消讀取歷史訊息事件
            this.$emit("update:isReadHistory", false);
        },
        // 設定客服為真人客服
        async setServiceChatToReal() {
            try {
                await firebaseSetServiceChatToReal(
                    this.currentReceiveUserId,
                    this.serviceChatId
                );
                await firebaseSetServiceChatToReal(
                    this.serviceChatId,
                    this.currentReceiveUserId
                );
            } catch (err) {
                console.log(err);
            }
        },
        // 設定客服為機器人客服
        async setServiceChatToBot() {
            try {
                await firebaseSetServiceChatToBot(
                    this.currentReceiveUserId,
                    this.serviceChatId
                );
                await firebaseSetServiceChatToBot(
                    this.serviceChatId,
                    this.currentReceiveUserId
                );
            } catch (err) {
                console.log(err);
            }
        },

        /**
         * 取得聊天對象資料
         * @param { type Number(數字) } role 角色身份代號
         * @param { type Number(數字) } userId 使用者 id
         */
        goProfile(role, userId) {
            window.open(
                `/#/user_list/${
                    role === 1 ? "provider_update" : "member_update"
                }/${userId}`
            );
        },
        /**
         * 取消 聊天訊息監聽
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對像 id
         */
        disconnect(userId, receiveUserId) {
            // 判斷是否有 user 資料 因為登出時會清出空
            if (this.serviceChatId !== undefined) {
                // 取消聊天室監聽
                firebaseConnectRef("chats")
                    .child(userId)
                    .child(receiveUserId)
                    .off();
            }
        },
    },
    beforeDestroy() {
        // 取消視窗滾動事件監聽
        this.$refs.chatContent.removeEventListener("scroll", this.getMoreData);
    },
};
</script>
