<template>
    <div
        class="cursor-pointer rounded-lg px-3 py-1.5 text-sm shadow-xl"
        :class="
            message.userId === user.banana_id
                ? 'mr-2 bg-blue-500 text-white'
                : 'ml-2 bg-white'
        "
    >
        <div class="flex">
            <span class="flex-1">已發送預訂諮詢，內容如下：</span>
        </div>
        <p>開始時間：{{ message.startedAt | formatDateTime }}</p>
        <p v-if="message.endedAt">
            結束時間：{{ message.endedAt | formatDateTime }}
        </p>
        <p>
            會面地點：{{ $checkLang(langDatas, "areas", message.district) }} |
            {{ message.location }}
        </p>
        <p>活動內容：{{ message.description }}</p>
        <p class="font-bold text-base mt-2">
            ＊本訊息為活動詢問內容並非實際訂單
        </p>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState("userStore", ["user"]),
        ...mapState("i18nStore", ["langDatas"]),
    },
};
</script>
